import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import PageBanner from "../components/Common/PageBanner";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";
import DangerousMarkdownContent from "../timewise-custom/components/DangerousMarkdownContent/DangerousMarkdownContent";

const PageNotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 404, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { t } = useI18next();

  return (
    <Layout>
      <SEO
        title="404"
        meta={{
          name: "robots",
          content: "noindex, nofollow, noimageindex",
        }}
      />
      <Navbar />
      <PageBanner
        pageTitle={t("404:title")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("404:breadcrumb")}
      />
      <div className="container pb-2">
        <div className="d-flex justify-content-center pb-4 pt-3">
          <Img
            style={{
              height: "100%",
              width: "100%",
              maxWidth: 404,
              borderRadius: 25,
              border: "3px solid white",
            }}
            imgStyle={{
              objectFit: "contain",
            }}
            fluid={data.file.childImageSharp.fluid}
          />
        </div>
        <p className="pb-4">
          <DangerousMarkdownContent content={t("404:text")} />
        </p>
        <form action="/search" className="search-box w-100 pb-5">
          <input
            autoComplete="off"
            type="text"
            name="expression"
            className="input-search"
            placeholder={t("search:placeholder")}
          />
          <button type="submit">
            <i className="flaticon-loupe"></i>
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default PageNotFound;
